import React from "react";
import { useHistory } from "react-router-dom";
import { ONBOARDING } from "../../constants/routes";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginWithEmailPassword from "./LoginWithEmailPassword";
import LoginWithOTP from "./LoginWithOTP";

const LoginFormFormik = ({
    currentTab,
    showForgotPassword,
    handleForgotPasswordClick,
    UpdatePass,
}) => {
    const history = useHistory();
    return (
        <div>
            {showForgotPassword ? (
                <ForgotPasswordForm UpdatePass={UpdatePass} />
            ) : (
                <>
                    <div>
                        {currentTab === 1 ? (
                            <LoginWithEmailPassword currentTab={currentTab} />
                        ) : (
                            <LoginWithOTP />
                        )}
                    </div>
                    <div className="flex justify-between items-center mt-2">
                        <div
                            className="cursor-pointer"
                            onClick={() => history.push(ONBOARDING)}
                        >
                            <p className="underline text-blue text-xs md:text-sm">
                                New user? Sign up here.
                            </p>
                        </div>

                        {currentTab === 1 && (
                            <div
                                className="cursor-pointer"
                                onClick={() => handleForgotPasswordClick()}
                            >
                                <p className="underline text-blue text-xs md:text-sm">
                                    Forgot Password
                                </p>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default LoginFormFormik;
