import React, { useState } from "react";
import P from "../../../shared/typography/P";
import ContactUSForm from "../../home/ContactUSForm";
import Faq from "../../home/Faq";
import Calculator from "../../home/Sections/Calculator";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";
import PriceSection from "../../home/Sections/PriceSection";
import ProjectCarouselSection from "../../home/Sections/ProjectCarouselSection";
import GovernmentSubsidies from "../GovernmentSubsidies";
import SubsidyWithReview from "../SubsidyWithReview";
import ContactEnquiry from "../sections/ContactEnquiry";
import InvestInsurance from "../sections/InvestInsurance";
import PricingHeroAndContent from "../sections/PricingHeroAndContent";

const subsidyData = {
    summary: (
        <P className="text-secondary">
            Switching to solar panels has never been this easy. With our simple
            pricing plans and government subsidies on residential solar panels,
            you can choose Hypersrot as your preferred solar partner. Under PM
            Surya Ghar Yojna Subsidy, you can enjoy benefits and get cashback of
            up to ₹78,000 for plants above 3KW.
        </P>
    ),
    data: [
        {
            title: "For Residential Households",
            list: [
                "A subsidy of ₹30,000 per kW is available for systems up to 2 kW.",
                "For additional capacity up to 3 kW, the subsidy is ₹18,000 per kW.",
                "The total subsidy for systems larger than 3 kW is capped at ₹78,000.",
            ],
        },
    ],
};

const contactData = {
    heading: {
        title: "Schedule a Call with Us",
        content:
            "Get in touch with our team to learn more about our products, pricing, and plans.",
    },
    general: {
        title: "General Inquiry",
        content:
            "Simply fill out the form, and our team will call you back to understand your needs",
    },
    investor: {
        title: "Investor Inquiry",
        content:
            "Looking to invest in solar energy? Reach out to us at marketing@hypersrot.com to learn more about the investment opportunity.",
    },
};

const IndividualHomes = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <PricingHeroAndContent video={true} />
            <PriceSection />
            <Calculator
                defaultIndex={3}
                heading="Calculator your savings"
                text="Just fill in a few simple details and know more about your ideal plan."
                bottomHeading="Save More with Solar!"
                bottomText="Installing solar panels not only helps the environment but also lowers the cost by up to 30% on your electricity bill."
            />
            <GovernmentSubsidies
                summary={subsidyData.summary}
                content={
                    <SubsidyWithReview
                        data={subsidyData.data}
                        onClick={() => setShowModal(true)}
                    />
                }
            />
            <ProjectCarouselSection />
            <InvestInsurance />
            <ContactUSForm data={contactData} enquiry={<ContactEnquiry />} />
            <Faq />
            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
            />
        </div>
    );
};

export default IndividualHomes;
