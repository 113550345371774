import React from "react";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import ListItem from "../../pricing/ListItem";

const SubsidyOverview = ({ data, onClick }) => {
    return (
        <div className="w-full ">
            <div className="flex flex-wrap items-start justify-between ">
                {data[0] && (
                    <div className="w-full md:w-[45%] xl:w-full">
                        <P className="mb-0.8 xl:mb-1.6 font-medium">
                            {data[0]?.title}
                        </P>
                        <div className="flex flex-wrap justify-between items-start mb-4">
                            {data[0].list.map((item, i) => (
                                <div
                                    key={i}
                                    className="w-full md:w-45%  xl:w-[45%] mb-1.2 xl:mb-0"
                                >
                                    <ListItem value={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {data[1] && (
                    <div className="w-full md:w-[45%] xl:w-full">
                        <P className="mb-0.8 xl:mb-1.6 font-medium">
                            {data[1].title}
                        </P>
                        {data[1].list.map((item, i) => (
                            <div
                                key={i}
                                className="mb-2.8 lg:mb-3 xl:mb-4 xl:w-[50%]"
                            >
                                <ListItem key={1} value={item} />
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {data[2] && (
                <div>
                    <P className="mb-0.8 xl:mb-1.6 font-medium">
                        {data[2].title}
                    </P>
                    {data[2].list.map((item, i) => (
                        <div
                            key={i}
                            className="mb-2.8 lg:mb-3.2 2xl:mb-4 xl:w-[50%]"
                        >
                            <ListItem value={item} />
                        </div>
                    ))}
                </div>
            )}

            <Button onClick={onClick}>Got an enquiry?</Button>
        </div>
    );
};

export default SubsidyOverview;
