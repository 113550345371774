import React from "react";
import { emailLight, phoneLight } from "../../resources/js/icons";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import ContactFormFormik from "../../shared/ui/Form/ContactFormFormik";

const HeroDetails = () => {
    return (
        <Section
            className={
                " flex justify-center min-h-[110vh] xl:min-h-[110vh] w-full py-[2rem]  lg:py-[3rem] xl:py-[5rem] 2xl:py-[10rem]"
            }
        >
            <Container
                className={
                    "flex items-center justify-center lg:hidden min-h-[110vh] xl:min-h-[110vh]"
                }
            >
                <H1 className={"mb-0.8 text-white text-nowrap"}>Contact Us</H1>
            </Container>
            <Container>
                <div className="hidden lg:flex justify-between lg:mt-[10rem] mx-5 flex-wrap  items-center gap-1 xl:mb-3.8">
                    <div className="w-full lg:w-[40%] flex flex-col gap-3.6">
                        <H2 className={"text-white"}>Contact Us</H2>
                        <div className="flex flex-row md:flex-col gap-3.6">
                            <div className="flex gap-1 items-center">
                                <img
                                    className="h-3"
                                    src={phoneLight}
                                    alt={"Phone number"}
                                />
                                <p
                                    className={
                                        "lg:underline text-xs text-white lg:text-xl"
                                    }
                                >
                                    +91 9970079570
                                </p>
                            </div>
                            <div className="flex gap-1 items-center">
                                <img
                                    className="h-3"
                                    src={emailLight}
                                    alt={"Email"}
                                />
                                <p
                                    className={
                                        "lg:underline text-xs text-white lg:text-xl"
                                    }
                                >
                                    marketing@hypersrot.com
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-0.8">
                            <H4 className="text-white">General enquiry</H4>
                            <P className={"text-white xl:w-[60%]"}>
                                Have questions about everything solar? Reach us
                                at marketing@hypersrot.com and we will get back
                                to you shortly.
                            </P>
                        </div>
                        <div className="flex flex-col gap-0.8">
                            <H4 className={"text-white"}>Investor enquiry</H4>
                            <P className={"text-white xl:w-[60%]"}>
                                Want to join us? Reach us at
                                marketing@hypersrot.com and we will get back to
                                you shortly.
                            </P>
                        </div>
                    </div>
                    <div className="flex-1 lg:w-1/3 2xl:w-1/2">
                        <ContactFormFormik />
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default HeroDetails;
