import React from "react";
import { useHistory } from "react-router-dom";
import { PRICING_LABELS } from "../../../constants/routes";
import {
    commercial,
    individualHomes,
    industrial,
    residentialSociety,
} from "../../../resources/js/images";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H2 from "../../../shared/typography/H2";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import { Carousel } from "../../../shared/ui/Carousel";

const PricingOverview = () => {
    const history = useHistory();
    const renderImages = (data) => {
        return data.map((item, i) => (
            <div key={i} className="flex flex-wrap justify-between items-start">
                <div className="w-full xl:w-[40%] mb-3.2 ml-2">
                    <H2>{item.heading}</H2>
                    {item?.subHeading && (
                        <H3 className="mt 1.6 mb-0.8 sm:mt-2.4 sm:mb-1.6 xl:my-0.8">
                            {item?.subHeading}
                        </H3>
                    )}
                    <P className="text-secondary mb-3">{item?.subText}</P>
                    <Button onClick={() => history.push(item?.link)}>
                        Get Details{" "}
                    </Button>
                </div>
                <div className="w-full xl:w-[50%]">
                    <img
                        className="w-full aspect-video rounded-sm sm:rounded-md  2xl:rounded-2"
                        src={item?.sectionImg?.img}
                        alt={item.sectionImg.alt}
                    />
                </div>
            </div>
        ));
    };
    const data = [
        {
            heading: "Residential Societies",
            subHeading: "Community Living Redefined",
            subText:
                "Discover vibrant and secure residential societies designed for modern living with state-of-the-art amenities.",
            sectionImg: {
                img: residentialSociety,
                alt: "A beautiful residential society with lush greenery",
            },
            link: PRICING_LABELS.RESIDENTIAL_SOCIETIES,
        },
        {
            heading: "Individual Homes",
            subheading: "Your Dream Home Awaits",
            subText:
                "Explore a variety of individual homes that cater to your style and preferences, providing comfort and privacy.",
            sectionImg: {
                img: individualHomes,
                alt: "A cozy individual home with a garden",
            },
            link: PRICING_LABELS.INDIVIDUAL_HOMES,
        },
        {
            heading: "Commercial Properties",
            subheading: "Space for Your Business",
            subText:
                "Find the perfect commercial properties strategically located to boost your business's visibility and growth.",
            sectionImg: {
                img: commercial,
                alt: "A modern commercial building in a bustling area",
            },
            link: PRICING_LABELS.COMMERCIAL,
        },
        {
            heading: "Industrial Spaces",
            subheading: "Efficient and Robust Infrastructure",
            subText:
                "Explore industrial spaces designed to meet the needs of various industries, ensuring functionality and scalability.",
            sectionImg: {
                img: industrial,
                alt: "A large industrial facility equipped for manufacturing",
            },
            link: PRICING_LABELS.INDUSTRIAL,
        },
    ];
    return (
        <Section>
            <Container c>
                <Carousel
                    slides={renderImages(data)}
                    headclass="h-full"
                    autoSlide={true}
                    flexPos="justify-start lg:justify-start"
                    autoSlideInterval={3000}
                />
            </Container>
        </Section>
    );
};

export default PricingOverview;
