import * as yup from "yup";
import {
    addressRegEx,
    emailRegEx,
    messageRegEx,
    nameRegEx,
    passRegEX,
    phoneRegEx,
    pinRegEx,
} from "../../../../constants/regularExpression";
export const contactUsFormValidator = yup.object().shape({
    firstName: yup
        .string()
        .required()
        .min(3)
        .max(50)
        .matches(
            nameRegEx,
            "First name should start with letter and not contain any special characters or numbers"
        )
        .label("First name"),
    lastName: yup
        .string()
        .required()
        .min(3)
        .max(50)
        .matches(
            nameRegEx,
            "Last name should start with letter and not contain any special characters or numbers"
        )
        .label("Last name"),
    email: yup
        .string()
        .required()
        .email()
        .matches(emailRegEx, "Required valid email")
        .label("Email"),
    phoneNumber: yup
        .string()
        .required()
        .matches(phoneRegEx, "Required valid Indian phone number")
        .max(10)
        .label("Phone number"),
    enquiry: yup.string(),
    queryMessage: yup.string().when("enquiry", {
        is: "Other",
        then: (schema) =>
            schema
                .required()
                .matches(
                    messageRegEx,
                    "Query should start with alphanumeric characters and contains only number letters or (,.-) symbols"
                )
                .min(3)
                .max(250)

                .label("Query message"),
        otherwise: (schema) => schema,
    }),
});

export const loginWithPasswordValidator = yup.object().shape({
    username: yup
        .string()
        .required()
        .test(
            "is-valid-username",
            "Username must be valid email or phone number",
            (value) => {
                if (!value) return false;
                return emailRegEx.test(value) || phoneRegEx.test(value);
            }
        )
        .label("Email/Phone"),

    password: yup
        .string()
        .required()
        .min(8)
        .matches(
            passRegEX,
            "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!"
        )
        .label("Password"),
});
export const loginWithOTPValidator = yup.object().shape({
    username: yup
        .string()
        .required()
        .test(
            "is-valid-username",
            "Username must be valid email or phone number",
            (value) => {
                if (!value) return false;
                return emailRegEx.test(value) || phoneRegEx.test(value);
            }
        )
        .label("Email/Phone"),
});

export const signupWithPasswordValidator = yup.object().shape({
    name: yup
        .string()
        .required()
        .min(3)
        .max(50)
        .matches(
            nameRegEx,
            "Name should start with letter and not contain any special characters or numbers"
        )
        .label("Full name"),

    password: yup
        .string()
        .required()
        .min(8)
        .matches(
            passRegEX,
            "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!"
        )
        .label("Password"),

    address: yup
        .string()
        .required()
        .matches(
            addressRegEx,
            "Address should start with alphanumeric chars or letters and may contain (,-) "
        )
        .label("Address"),
    city: yup.string().required().max(100).label("City"),
    state: yup.string().required().max(80).label("State"),
    pincode: yup
        .string()
        .required()
        .matches(pinRegEx, "Pincode must be valid format")
        .label("Pincode"),
    electricalBill: yup
        .number()
        .min(10000, "Electricity bill must be greater than or equal to 10,000")
        .max(
            1000000,
            "Electricity bill must be less than or equal to 10,00,000"
        )
        .required()
        .label("Electricity bill"),
});
