import React from "react";
import ModalAlt from "../../../components/modal/ModalAlt";
import ContactFormFormik from "../../../shared/ui/Form/ContactFormFormik";

const EnquiryPopUp = ({
    isOpen,
    onClose,
    enquiry,
    callback,
    disabled = true,
}) => {
    return (
        <ModalAlt
            isOpen={isOpen}
            backgroundColor="transparent"
            onClose={onClose}
            innerTimes={false}
            topPadding={"30px"}
        >
            <ContactFormFormik
                disabled={disabled}
                wrapperClass={
                    "bg-white rounded-1.5 shadow-md py-3.6 px-3 w-[60%] md:w-full"
                }
                callback={callback}
                enquiry={enquiry}
            />
        </ModalAlt>
    );
};

export default EnquiryPopUp;
