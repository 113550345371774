import { useField } from "formik";
import React from "react";

const FormSelect = ({ label, required, errorMsg, data, ...rest }) => {
    const [field, meta] = useField(rest);

    return (
        <div className="flex flex-col">
            <label
                className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                htmlFor={field.name}
            >
                {label} {required && <span className="text-red">*</span>}
            </label>
            <div
                className={`  px-px py-px border  ${
                    errorMsg && errorMsg?.length > 0
                        ? "border-red"
                        : "bg-custom-gradient"
                }  rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
                <select
                    className=" w-full rounded-lg px-1 py-1  lg:py-1.2
                    text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl"
                    {...rest}
                    {...field}
                >
                    {data.map((item, i) => (
                        <option
                            // className="text-xl"
                            key={i}
                        >
                            {item}
                        </option>
                    ))}
                </select>
                {meta.touched && meta.error && (
                    <>
                        <span className="text-red text-sm xl:text-base flex items-center flex-wrap gap-0.5">
                            {meta.error[field.name]}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default FormSelect;
