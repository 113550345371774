import { Form, Formik } from "formik";
import React, { useState } from "react";
import { enquiryList } from "../../../constants/EnquiryData";
import { ERROR_MSG } from "../../../constants/common";
import { SET_ERROR } from "../../../constants/contexConstant";
import { CONTACTUS_FORM_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { check } from "../../../resources/js/images";
import { handleResentOTP } from "../../../services/apiRequest";
import request from "../../../services/request";
import H4 from "../../typography/H4";
import P from "../../typography/P";
import Button from "../Button";
import OTP from "../OTP";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import { contactUsFormValidator } from "./schema";

const ContactFormFormik = ({
    wrapperClass = " w-full lg:bg-white lg:rounded-1.5 lg:shadow-md lg:py-5.6 lg:px-4",
    disabled = false,
    enquiry,
    callback,
}) => {
    const { dispatch } = useContextState();
    const [formData, setFormData] = useState({
        otp: "",
    });
    const [done, setDone] = useState(false);
    const [errors, setErrors] = useState({});
    const [getOtp, setGetOtp] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const handleSubmit = async (values, actions, mode, isValid) => {
        if (mode) {
            if (!isValid) return;
            handleResentOTP(mode, values.phoneNumber, setLoading, dispatch);
            return;
        }

        let err = {};

        if (getOtp) {
            if (!formData.otp) err.otp = "Otp is Required";
            else if (formData.otp && formData.otp.length !== 6)
                err.otp = "Invalid OTP";
        }
        if (Object.keys(err).length > 0) {
            setErrors(err);
        } else {
            setErrors({});
            try {
                let reqData = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phone: `91${values.phoneNumber}`,
                    querySource: window.location.href,
                    queryMessage:
                        values.queryMessage && values.enquiry === "Other"
                            ? values.queryMessage
                            : values.enquiry,
                    otp: getOtp ? formData.otp : undefined,
                };

                let endpoint = CONTACTUS_FORM_ENDPOINT;
                setLoading(true);
                await request.post({
                    endpoint,
                    body: reqData,
                });
                setLoading(false);

                if (!getOtp) {
                    setGetOtp(true);
                } else {
                    setDone(true);
                    callback && callback();
                    setTimeout(() => {
                        setDone(false);
                        setFormData({
                            otp: "",
                        });
                        setGetOtp(false);
                        callback && callback();
                    }, 5000);
                }
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    const handleDone = () => {
        setDone(false);
        setFormData({
            otp: "",
        });
        setGetOtp(false);
    };

    /* @DESC:: new form validation @habib610 Thu October 17,2024 */
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        enquiry: enquiry ? enquiry : "Looking to Purchase Solar",
        queryMessage: "",
        otp: "",
    };

    return (
        <div className={` mx-auto ${wrapperClass}  `}>
            {done ? (
                <>
                    <div className="flex flex-col items-center">
                        <img src={check} alt="check" className="w-44 h-44" />
                        <P className={"text-center w-[70%] my-5"}>
                            An email will be sent to your registered email id
                            and we will contact you shortly to confirm your site
                            booking!
                        </P>
                    </div>
                    <div className="mt-2 flex">
                        <Button
                            // type="submit"
                            onClick={handleDone}
                            className="w-full flex justify-center px-1  sm:px-1.8  2xl:px-2 py-0.8 sm:py-1.5 xl:py-1.5 2xl:py-1.6"
                        >
                            Done
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <H4 className={"font-semibold  mb-1.6"}>
                        Fill out your details and we'll get back to you ASAP!
                    </H4>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={contactUsFormValidator}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            isSubmitting,
                            resetForm,
                            isValid,
                            dirty,
                        }) => (
                            <Form>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2  xl:mt-3.6">
                                    <FormInput
                                        name="firstName"
                                        label={"First Name"}
                                        required={true}
                                        type="text"
                                    />
                                    <FormInput
                                        label={"Last Name"}
                                        name={"lastName"}
                                        type="text"
                                        required={true}
                                    />
                                </div>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6 ">
                                    <FormInput
                                        name={"email"}
                                        label={"Email"}
                                        required={true}
                                        type="text"
                                    />
                                    <FormInput
                                        label={"Phone Number"}
                                        name={"phoneNumber"}
                                        required={true}
                                        type="text"
                                    />
                                </div>

                                <div className="my-1.6 xl:my-3.6">
                                    <div>
                                        {disabled ? (
                                            <FormInput
                                                label="Select an enquiry"
                                                name={"enquiry"}
                                                type="text"
                                                disabled={true}
                                            />
                                        ) : (
                                            <FormSelect
                                                label="Select an enquiry"
                                                data={enquiryList}
                                                name="enquiry"
                                                disabled={disabled}
                                            />
                                        )}
                                    </div>
                                </div>

                                {values.enquiry === "Other" && (
                                    <div className="my-1.6 xl:my-3.6">
                                        <FormInput
                                            label={"Enter Enquiry"}
                                            name={"queryMessage"}
                                        />
                                    </div>
                                )}
                                {getOtp && (
                                    <OTP
                                        name="otp"
                                        otp={formData.otp}
                                        onChange={handleChange}
                                        error={errors?.otp ? true : false}
                                        errorMessage={errors.otp}
                                        handleResentOTP={(mode) =>
                                            handleSubmit(
                                                values,
                                                { resetForm },
                                                mode,
                                                isValid
                                            )
                                        }
                                    />
                                )}
                                <div className="mt-2">
                                    <Button
                                        type="submit"
                                        isLoading={isSubmitting}
                                        disabled={
                                            isSubmitting || !(isValid && dirty)
                                        }
                                        className="w-full flex justify-center px-1  sm:px-1.8  2xl:px-2 py-0.8 sm:py-1.5 xl:py-1.5 2xl:py-1.6"
                                    >
                                        {getOtp ? "Call Back" : "Get OTP"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};

export default ContactFormFormik;
