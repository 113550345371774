import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { container } from "../../../components/animation/landingAnimation";
import { PRICING_LABELS } from "../../../constants/routes";
import {
    background,
    commercial,
    individualHomes,
    industrial,
    residentialSociety,
    solarPricing,
} from "../../../resources/js/images";
import { homeVideoUrl } from "../../../resources/js/videos";
import {
    headingsTwo,
    slideOpacityLeft,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H2 from "../../../shared/typography/H2";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import { Carousel } from "../../../shared/ui/Carousel";
import Hero from "../../home/Hero";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";

const tabData = [
    {
        id: 2,
        name: "Individual homes",
        path: PRICING_LABELS.INDIVIDUAL_HOMES,
        tag: "Pricing Benefits",
        tagLine: "Exploring the Solar Revolution: A Sustainable Energy Journey",
        date: "March 20, 2024",
        heading: "Individual Homes",
        subHeading: "Purchase Plan",
        highlightImg: {
            img: solarPricing,
            alt: "solar_pricing",
        },

        carousel: [
            {
                id: 1,
                subHeading: "Purchase Plan",
                subText:
                    "In this plan, you’ll have to pay the entire solar plant cost upfront and purchase your solar panel. You can take advantage of the government subsidy (PM Surya Ghar Yojna Subsidy) and get cashback up to ₹78,000 for solar plants above 3KW. The subsidy reduces the payback period to 2-3 years, compared to the previous 4-5 years without it. Start your solar journey now!",
                sectionImg: {
                    img: individualHomes,
                    alt: "individual_homes",
                },
            },
            {
                id: 2,
                subHeading: "Loan Plan",
                subText:
                    " In this plan, you can start your solar journey by just paying 30% upfront cost and taking the solar on loan. There are government subsidies to benefit you and reduce your loan duration and loan balance by depositing the subsidy amount in the bank directly. Your payback period is reduced to 3-4 years, and after that, solar will provide free electricity for the next 25 years.",
                sectionImg: {
                    img: individualHomes,
                    alt: "individual_homes",
                },
            },
        ],
    },
    {
        id: 3,
        name: "Residential Societies",
        path: PRICING_LABELS.RESIDENTIAL_SOCIETIES,
        tag: "Pricing Benefits",
        tagLine: "Exploring the Solar Revolution: A Sustainable Energy Journey",
        date: "March 20, 2024",
        heading: "Residential Societies",
        subHeading: "Purchase Plan",
        highlightImg: {
            img: solarPricing,
            alt: "solar_pricing",
        },

        carousel: [
            {
                id: 1,
                subHeading: "Purchase Plan",
                subText:
                    "In this plan, you need to pay the entire solar plant cost upfront. Additionally, PM Surya Ghar Yojna Subsidy, a government subsidy, helps you get a cashback of ₹18,000 per kW for solar plant sizes up to 500 kW. The regular payback period for solar panels is reduced to 1-2 years with the subsidy, down from 3-4 years without it.",
                sectionImg: {
                    img: residentialSociety,
                    alt: "residential_society",
                },
            },
            {
                id: 2,
                subHeading: "Lease Plan",
                subText:
                    "Invest in solar power with ZERO upfront payment and start saving up to 50% on your existing electricity bill. In this plan, you have to pay for using solar for 5 years. Hypersrot will handle maintenance and insure your plan for the 5-year lease duration at no extra cost. At the end of the term, the plant will be handed over to the society at no extra cost.",
                sectionImg: {
                    img: residentialSociety,
                    alt: "residential_society",
                },
            },
        ],
    },
    {
        id: 4,
        name: "Commercial",
        path: PRICING_LABELS.COMMERCIAL,
        tag: "Pricing Benefits",
        tagLine: "Exploring the Solar Revolution: A Sustainable Energy Journey",
        date: "March 20, 2024",
        heading: "Commercial",
        subHeading: "Purchase Plan",
        highlightImg: {
            img: solarPricing,
            alt: "solar_pricing",
        },

        carousel: [
            {
                id: 1,
                subHeading: "Purchase Plan",
                subText:
                    "When you choose our purchase plan, you’ll have to cover the initial cost of the solar plant entirely. You can also enjoy accelerated depreciation benefits on solar panels. Plus, with a typical payback period of 4-5 years, owning a solar plant is much easier than you think.",
                sectionImg: {
                    img: commercial,
                    alt: "commercial",
                },
            },
            {
                id: 2,
                subHeading: "Loan Plan",
                subText:
                    "In our loan plan, embracing solar energy is easy; all you have to do is pay 30% of the upfront cost and take the solar on a loan. Easy, isn’t it? Also, you can take advantage of the accelerated depreciation of solar plants. With a typical payback period of 5-6 years, you can enjoy free electricity for the next 20 years.",
                sectionImg: {
                    img: commercial,
                    alt: "commercial",
                },
            },
            {
                id: 3,
                subHeading: "Lease Plan For Own Use",
                subText:
                    "Embrace solar with ZERO upfront payment and start saving up to 30% on your existing electricity bill. In this plan, you’ll have to pay for your solar usage for the next 5 years. Hypersrot will cover all maintenance and insurance of your plan for the lease duration. At the end of the term, the solar plant will be yours at no additional cost.",
                sectionImg: {
                    img: commercial,
                    alt: "commercial",
                },
            },
            {
                id: 4,
                subHeading: "Lease Plan for Tenants:",
                subText:
                    "Maximize your profits by adding solar to your already rented-out spaces. You can use the rooftops and generate revenue by installing solar panels on the rooftops. With our ZERO upfront lease plan, tenants can save money on electricity and owners can generate additional rental income, a win-win for everyone.",
                sectionImg: {
                    img: commercial,
                    alt: "commercial",
                },
            },
        ],
    },
    {
        id: 5,
        name: "Industrial",
        path: PRICING_LABELS.INDUSTRIAL,
        tag: "Pricing Benefits",
        tagLine: "Exploring the Solar Revolution: A Sustainable Energy Journey",
        date: "March 20, 2024",
        heading: "Industrial",
        subHeading: "Purchase Plan",
        highlightImg: {
            img: solarPricing,
            alt: "solar_pricing",
        },
        subText:
            "The consumer has to pay the entire solar plant cost upfront. They can get the benefits of accelerated depreciation of solar plants. A typical payback period is around 7-8 Years.",
        sectionImg: {
            img: industrial,
            alt: "individual_homes",
        },
        carousel: [
            {
                id: 1,
                subHeading: "Purchase Plan",
                subText:
                    "In our purchase plan, you have to pay the complete solar plant cost upfront. Additionally, you get the benefits of accelerated depreciation on solar plants. The typical payback period for this type of plan is usually 7-8 years.",
                sectionImg: {
                    img: industrial,
                    alt: "individual_homes",
                },
            },
            {
                id: 2,
                subHeading: "Loan Plan",
                subText:
                    "In this plan, you can take solar on a loan by paying 30% upfront and taking solar on a loan while also enjoying the benefits of accelerated depreciation on solar plants. For a loan plan, the standard payback period is 8-10 years, and after that period, you can use free electricity for the next 15 years.",
                sectionImg: {
                    img: industrial,
                    alt: "individual_homes",
                },
            },
        ],
    },
];

const PricingHeroAndContent = ({ video = false }) => {
    const history = useHistory();
    const [active, setActive] = useState(tabData[0]);
    const [showModal, setShowModal] = useState(false);

    const handleTabletView = (path) => {
        history.push(path);
    };
    const location = useLocation();
    const segments = location.pathname.split("/").filter((segment) => segment);

    const lastSegment = segments[segments.length - 1];

    useEffect(() => {
        if (!location) {
            setActive(tabData[0]);
        } else {
            let data = tabData.find(
                (item) => item.path.slice(9) === lastSegment
            );
            setActive(data);
        }
    }, [location]);
    const renderImages = (data) => {
        return data.map((item, i) => (
            <div key={i} className="flex flex-wrap justify-between items-start">
                <div className="w-full xl:w-[40%] mb-3.2">
                    {item?.subHeading && (
                        <H3 className="mt 1.6 mb-0.8 sm:mt-2.4 sm:mb-1.6 xl:my-0.8">
                            {item?.subHeading}
                        </H3>
                    )}
                    <P className="text-secondary">{item?.subText}</P>
                </div>
                <div className="w-full xl:w-[50%]">
                    <img
                        className="w-full aspect-video rounded-sm sm:rounded-md  2xl:rounded-2"
                        src={item?.sectionImg?.img}
                        alt={item.sectionImg.alt}
                    />
                </div>
            </div>
        ));
    };

    const onClick = () => {};

    return (
        <div>
            <Hero
                url={video ? homeVideoUrl : background}
                type={video ? "video" : "img"}
                wrapperClass=" xl:h-[830px]  2xl:h-screen"
                content={
                    <Container>
                        <div
                            style={{
                                paddingTop:
                                    "clamp(3rem, calc((100vw - 1439px) * 80), 5rem)",
                            }}
                        >
                            <span className="text-gray-250 text-base">
                                Our Solar for
                            </span>
                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={translateContainer}
                                className="text-white w-full 
                        2xs:w-[94%] sm:w-[600px] md:w-[680px] lg:w-[700px] xl:w-[810px] 2xl:w-[1100px] 3xl:w-[1200px]
                        "
                            >
                                <SplitText
                                    className="gap-x-0.8 sm:gap-x-1.2 flex flex-wrap text-white "
                                    variants={wavyHeadings}
                                >
                                    {active.name}
                                </SplitText>
                            </motion.div>

                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={container}
                                className="overflow-x-hidden"
                            >
                                <motion.div variants={headingsTwo}>
                                    <H3 className="font-normal text-white   sm:text-[2.6rem] lg:text-5xl">
                                        {active.tagLine}
                                    </H3>
                                </motion.div>

                                <motion.div variants={slideOpacityLeft}>
                                    <Button
                                        variant="secondary"
                                        className="mt-2.4 xl:mt-2.4 2xl:mt-4.8"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Get quote
                                        <span>
                                            <svg
                                                width="13"
                                                height="14"
                                                className="  w-[14px] h-[14px]  lg:w-[18px] lg:h-[18px] xl:w-[24px] xl:h-[24px] "
                                                viewBox="0 0 13 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    x="0.38162"
                                                    y="0.9866"
                                                    width="12.0268"
                                                    height="12.0268"
                                                    rx="6.01338"
                                                    stroke="currentColor"
                                                    strokeWidth="0.76324"
                                                />
                                                <path
                                                    d="M4 9.39498L8.79 4.60498M8.79 4.60498H4.479M8.79 4.60498V8.91598"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </Button>
                                </motion.div>
                            </motion.div>
                        </div>
                    </Container>
                }
            />
            <Section>
                <Container>
                    <H2
                        className={`${
                            active?.subHeading ? "" : "mb-0.8 xl:mb-3.2"
                        }`}
                    >
                        {active.heading}
                    </H2>
                    {active?.carousel && (
                        <Carousel
                            slides={renderImages(active?.carousel)}
                            headclass="h-full"
                            autoSlide={true}
                            flexPos="justify-start lg:justify-start"
                            autoSlideInterval={3000}
                        />
                    )}
                </Container>
            </Section>

            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
                disabled={false}
            />
        </div>
    );
};

export default PricingHeroAndContent;
