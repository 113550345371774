import React, { useState } from "react";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";
import LoginFormFormik from "./LoginFormFormik";

const HeroDetails = () => {
    const [currentTab, setCurrentTab] = useState(1);
    const [isSignUp, setIsSignUp] = useState(false);
    const [showPassword, setShowForgotPassword] = useState(false);
    const [update, setUpdate] = useState(false);

    const changeMethod = () => {
        setIsSignUp((isSignUp) => !isSignUp);
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPassword(!showPassword);
    };

    const UpdatePass = () => {
        setUpdate(!update);
    };
    return (
        <Container>
            <div className="flex justify-center xl:justify-between flex-wrap flex-col xl:flex-row items-center xl:gap-9 py-[10rem]  lg:py-[13rem] xl:py-[15rem] 2xl:py-[18rem] ">
                <div className="w-full hidden xl:w-[30%] xl:flex flex-col gap-4.8 xl:ml-4">
                    <H1 className="text-white font-bold">Login</H1>
                    <P className="text-white">
                        Use your account credential to login into your account
                    </P>
                </div>
                <div className="flex-1 w-full xl:w-1/5 2xl:w-1/2 xl:mr-4">
                    <div className="w-full mx-auto bg-white rounded-1.5 shadow-md py-5.6 px-4">
                        <div className="flex justify-between gap-0.6 items-center mb-2 xl:mb-0">
                            <H2 className="font-normal xl:text-3xl mb-1 xl:mb-0">
                                {update
                                    ? "Update Your Password"
                                    : showPassword
                                    ? "Reset Your Password"
                                    : isSignUp
                                    ? "SignUp"
                                    : "Login"}
                            </H2>
                            {showPassword || update ? (
                                <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowForgotPassword(false);
                                        setUpdate(false);
                                    }}
                                >
                                    <p className="underline text-blue text-xs md:text-sm">
                                        Have an account? Log in here
                                    </p>
                                </div>
                            ) : (
                                !isSignUp && (
                                    <SwitchingTabs
                                        options={["OTP", "Password"]}
                                        currentTab={currentTab}
                                        onTabClick={(index) =>
                                            setCurrentTab(index)
                                        }
                                        border="!rounded-[3rem] lg:rounded-[3rem]"
                                    />
                                )
                            )}
                        </div>

                        <LoginFormFormik
                            currentTab={currentTab}
                            isSignUp={isSignUp}
                            changeMethod={changeMethod}
                            showForgotPassword={showPassword}
                            handleForgotPasswordClick={
                                handleForgotPasswordClick
                            }
                            UpdatePass={UpdatePass}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default HeroDetails;
