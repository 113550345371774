import React, { useState } from "react";

import P from "../../../shared/typography/P";
import ContactUSForm from "../../home/ContactUSForm";
import Faq from "../../home/Faq";
import Calculator from "../../home/Sections/Calculator";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";
import PriceSection from "../../home/Sections/PriceSection";
import ProjectCarouselSection from "../../home/Sections/ProjectCarouselSection";
import GovernmentSubsidies from "../GovernmentSubsidies";
import SubsidyWithReview from "../SubsidyWithReview";
import ContactEnquiry from "../sections/ContactEnquiry";
import InvestInsurance from "../sections/InvestInsurance";
import PricingHeroAndContent from "../sections/PricingHeroAndContent";

const subsidyData = {
    summary: (
        <P className="text-secondary">
            The government has many initiatives supporting a clean energy future
            by providing incentives in the form of subsidies and tax benefits
            for panels of all sizes. You can browse our plans and find the one
            most beneficial to you.
        </P>
    ),
    data: [],
};

const contactData = {
    heading: {
        title: "Learn More About Us",
        content:
            "Schedule a call with our team to learn more about our products, pricing, and plans",
    },
    general: {
        title: "General Inquiry",
        content:
            "Simply fill in the form, and our experts will call you and provide all the assistance you need to make an informed decision.",
    },
    investor: {
        title: "Investor Inquiry",
        content:
            "Do you want to partner with Hypersrot? Get in touch with our team to learn more about the opportunities and how you can be a part of them.",
    },
};
const Industrial = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <PricingHeroAndContent />
            <PriceSection />
            <Calculator
                defaultIndex={2}
                heading="Calculate & Save"
                text="Get an estimation for your desired solar plan by filling out a few details."
                bottomHeading="Solar Power for All"
                bottomText="Finding an ideal plan can be challenging. Use our calculator to find the plan that is best for you."
            />
            <GovernmentSubsidies
                summary={subsidyData.summary}
                content={
                    <SubsidyWithReview
                        data={subsidyData.data}
                        onClick={() => setShowModal(true)}
                    />
                }
            />
            <ProjectCarouselSection />
            <InvestInsurance />
            <ContactUSForm data={contactData} enquiry={<ContactEnquiry />} />
            <Faq />
            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
            />
        </div>
    );
};

export default Industrial;
